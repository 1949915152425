import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getPartners} from "../../../redux/actions";
import PartnersModal from "../../Modals/partnersModal";



function FullPartners(props) {
    const {partners, getPartners} = props
    useEffect(() => {
        let mounted = true
        if (partners.length === 0) {
            getPartners()
        }
        return function cleanup() {
            mounted = false
        }
    }, [])
    const [modalShow, setModalShow] = useState(false);
    const [activePartner, setActivePartner] = useState(null);
    const showModal = (partner) => {
        setModalShow(true);
        setActivePartner(partner);
    }
    return (
        <div>
            <div className="home main position-relative">
                <div className="mt-5 pt-5 full-partners">
                    <div className="my-3">
                        <span className="line">Tərəfdaşlar</span>
                    </div>
                    <div className="mb-5">
                        <p>Tərəfdaşlar</p>
                    </div>
                    <div className="row ">
                        {
                            partners.map((p,i) => (
                                <div key={i} className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    <img onClick={()=> showModal(p)} src={p.image.url} alt=""/>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>
            <PartnersModal
                partners={activePartner}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
}


const mapStateToProps = ({partners}) => {
    return {partners};
}

export default connect(mapStateToProps, {getPartners})(FullPartners);
