import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import {getContact} from "../../../redux/actions";
import {connect} from "react-redux";
import Social from "../Social/Social";
import { useTranslation } from 'react-i18next';
import Footer from "../../Layout/Footer/Footer";

function Contact(props) {
    const {contact, getContact} = props
    const { t, i18n } = useTranslation();

    useEffect(() => {
        console.log(contact)
        getContact()
    }, [])

    return (
        <div className="home main position-relative animated fadeIn">
            <div className="container-fluid">
                <div className="row mt-5 pt-5 animated zoomIn">
                    <div className="col-lg-6 col-12">
                        <div className="section-text mt-3">
                            <h2>{t('contact.contact')}</h2>
                        </div>
                        <div className="section-one-leaf">
                            <div className="leafRight">
                                <img src={Image.Leaf2} alt=""/>
                            </div>
                        </div>
                        <>
                            <div className="donate-contact">
                                <p>{t('contact.wantToDonate')}</p>
                                <span>
                                   {t('contact.callUs')}
                                </span>
                                <p>{contact.phone}</p>
                                <span>Address :</span>
                                <p><Link>{contact.address}</Link></p>
                                {/* <span>Fax :</span>
                                <p><Link>{contact.fax}</Link></p> */}
                                <span>{t('contact.ourEmail')}:</span>
                                <p><Link>{contact.email}</Link></p>
                            </div>
                        </>
                    </div>
                    <div className="col-lg-6 col-12 position-relative animated fadeInRightBig">
                        <div>
                            <img src={Image.Girl} alt="" className="girl"/>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={Image.AboutUs} alt="" className="donation"/>
                </div>
                {/*<Social/>*/}
                <Footer/>
            </div>
        </div>
    );
}


const mapStateToProps = ({contact}) => {
    return {contact};
};

export default connect(mapStateToProps, {getContact})(Contact);
