import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Image from "../../../assets/img/Images";
import siteUrl from "../../../const/url";
import { connect } from "react-redux";
import { getSubject } from "../../../redux/actions";
import { useTranslation } from "react-i18next";

function SupportLayf(props) {
  const { t } = useTranslation();
  const { getSubject, subject } = props;
  useEffect(() => {
    if (subject.length === 0) {
      getSubject();
    }
  }, [subject]);

  const perPage = 4;
  const [page, setPage] = useState(0);
  const [supportList, setSupportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rangeBackgroundColor, setRangeBackgroundColor] = useState("");
  useEffect(() => {
    const getSupportList = () => {
      setLoading(true);
      fetch(
        `${siteUrl}api/GetSupport?GetDataCount=${perPage}&PerDataCount=${page}`
      )
        .then((res) => res.json())
        .then((res) => {
          setSupportList([...supportList, ...res]);

          setLoading(false);
        });
    };
    getSupportList();
    supportList.map((s) => {
      setRangeBackgroundColor(
        `linear-gradient(90deg, #000 0%, #000 ${Math.round(
          (s.accumulatedAmount / s.neededAmount) * 100
        )}%, #fff ${Math.round(
          (s.accumulatedAmount / s.neededAmount) * 100
        )}%, #fff 100%)`
      );
    });
  }, [page]);

  console.log(rangeBackgroundColor);
  return (
    <div>
      <div className="main-100 position-relative">
        <div className="container-fluid">
          <div className="row justify-content-between mt-5 align-items-center">
            <div className="col-lg-6 section-text mt-5">
              <h2>Dəstək ver</h2>
            </div>
            <div className="support-button">
              <div className=" support-links">
                <select className="  form-select support-select">
                  <option className="line-link">Hamısı</option>
                  {subject.map((s) => (
                    <option className="line-link">{s.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="payment-leaf1">
            <img src={Image.Leaf2} alt="" />
          </div>
          <div>
            <img src={Image.OurCauses} alt="" className="donation" />
          </div>
          <div className="support-gallery animated zoomIn">
            <div className="row">
              {supportList.map((s, i) => (
                <div className="col-xl-3 col-lg-4 col-12 mb-5">
                  <div className="box">
                    <div>
                      <img src={s.photo?.url} alt="" />
                    </div>
                    <div className="box-text">
                      <span>{s.title}</span>
                      {/* <p>{s.description}</p> */}
                      <p 
                        dangerouslySetInnerHTML={{
                          __html: s.description,
                        }}
                      />
                    </div>
                    <div className="box-range">
                      <div className="range-percent d-flex justify-content-between align-items-center">
                        <p>Ümumi yığılan məbləğ</p>
                        <span>
                          {s.accumulatedAmount > s.neededAmount
                            ? "100"
                            : Math.floor(
                                (s.accumulatedAmount / s.neededAmount) * 100
                              )}{" "}
                          %
                        </span>
                      </div>
                      <Form>
                        <Form.Group controlId="formBasicRange">
                          <div style={{ position: "relative", width: "100%" }}>
                            <Form.Control
                              type="range"
                              value={Math.round(
                                (s.accumulatedAmount / s.neededAmount) * 100
                              )}
                              style={{
                                background: "transparent", // Arkaplanı şeffaf yapın
                                zIndex: 1, // range input'u diğer elemanların üstüne getirin
                                position: "relative", // pozisyonu relative olarak ayarlayın
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                border: "1px solid #ced4da", // Bozulmayan kısmın kenarlık rengi
                                borderRadius: "0.2rem", // İstediğiniz bir köşe yuvarlama miktarını ekleyin
                                background: `linear-gradient(90deg, #4daf42 0%, #4daf42 ${Math.round(
                                  (s.accumulatedAmount / s.neededAmount) * 100
                                )}%, #fff ${Math.round(
                                  (s.accumulatedAmount / s.neededAmount) * 100
                                )}%, #fff 100%)`,
                              }}
                            />
                          </div>
                        </Form.Group>
                      </Form>
                      <div className="d-flex justify-content-between range-about">
                        <p>Yığılan məbləğ: {s.accumulatedAmount} Azn</p>
                        <p>Yığılacaq məbləğ : {s.neededAmount} Azn</p>
                      </div>
                    </div>
                    <div className="box-link">
                      <Link to={`/donate-now/${s.id}`}>
                        <p>Ətraflı bax</p>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="links d-flex align-items-center justify-content-center mb-4">
              {supportList.length >= perPage ? (
                <button
                  className="btn-load-more"
                  onClick={() => setPage(page + perPage)}
                >
                  {loading ? "Loading..." : t("loadMore")}
                </button>
              ) : (
                " "
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ subject }) => {
  return { subject };
};

export default connect(mapStateToProps, { getSubject })(SupportLayf);
