import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getWorkers} from "../../../redux/actions";
import { useTranslation } from 'react-i18next';

function Staff(props) {
    const {t} = useTranslation()
    const {workers, getWorkers} = props
    useEffect(() => {
        let mounted = true
        if (workers.length === 0) {
            getWorkers()
        }
        return function cleanup() {
            mounted = false
        }
    },[])

    const [visible, setVisible] = useState(4)

    const showMoreItems = () => {
        setVisible(prevValue => prevValue + 4);
    };

    return (
        <div className="our-mission position-relative animated fadeIn">
            <div className="container-fluid">
                <div className="section-text mt-5 pt-5 animated fadeInLeft">
                    <h2>İdarə heyyəti</h2>
                </div>
                <div className="row animated zoomIn my-5">
                    {
                        workers.slice(0, visible).map((w,i) => (
                            <div key={i} className="col-xl-3 col-lg-6 col-sm-6 col-12 staff mb-sm-0 mb-5">
                                <Link to={`/full-staff/${w.id}`}>
                                    <div className="staff-inform">
                                        <img src={w.image.url} alt=""/>
                                        <div className="about">
                                            <p>{w.fullName}</p>
                                            <span>{w.profession}</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
                <div className="links d-flex align-items-center justify-content-center my-4">
                    {workers.length > 4 ? <button onClick={showMoreItems}>{t('loadMore')}</button> : ""}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({workers}) => {
    return {workers};
}

export default connect(mapStateToProps, {getWorkers})(Staff);
