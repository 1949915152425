import React, {useEffect, useState} from 'react';
import Image from "../../../assets/img/Images";
import {Link} from "react-router-dom";
import history from "../../../const/history";
import {getContact, getSocials} from "../../../redux/actions";
import {connect} from "react-redux";

function Footer(props) {
    const {contact, getContact} = props
    useEffect(() => {
        getContact()
    }, [])
    const [showMenu, setShow] = useState(true);


    const {socials, getSocials} = props
    useEffect(() => {
        if (socials.length === 0) {
            getSocials();
        }
    }, [])

    useEffect(() => {
        console.log(window.location)
        if (window.location.hash === "#/donate-now"  || window.location.hash === '#/payment' || window.location.hash === '#/free-donate' || window.location.hash === '#/apply-form'
            || window.location.hash === '#/thank-payment' || window.location.hash.includes('#/full-free-support')  || window.location.hash.includes('#/full-staff') || window.location.hash === '#/staff' || window.location.hash === '#/contact'
        ) {
            setShow(false);
        } else {
            setShow(true)
        }

        history.listen((location) => {
            if (window.location.hash === "#/donate-now" || location.hash === '#/free-donate' || location.hash === '#/apply-form' || window.location.hash === '#/payment'
                || location.hash === '#/thank-payment' || window.location.hash.includes('#/full-free-support') || window.location.hash.includes('#/full-staff') || window.location.hash === '#/staff' || window.location.hash === '#/contact' ) {
                setShow(false)
            } else {
                setShow(true)
            }

        });
    }, [props.match])

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    return (
        <div>
            {showMenu &&
            <div className="footer position-relative">
                <div>
                    <img src={Image.LayfLogo} alt=""/>
                </div>
                <div className='mt-3 rules'>
                    <Link to="/rules"><span>İstifadəçi şərtləri</span></Link>
                </div>
                <div className="footer-text">
                    <p>{contact.phone}</p>
                    <Link ><span>{contact.email}</span></Link>
                </div>
                 <div className="d-lg-flex justify-content-between align-items-center footer-social-icon col-10 p-0">
                    <ul className="d-flex">
                        {
                            socials.map((s,i) => (
                                <li><a target="_blank" href={s.link}><img style={{height:'22px'}} src={s.photo.url} alt=""/></a></li>
                            ))
                        }
                        {/*<li><Link><img src={Image.Facebook} alt=""/></Link></li>*/}
                        {/*<li><Link><img src={Image.Twitter} alt=""/></Link></li>*/}
                        {/*<li><Link><img src={Image.Instagram} alt=""/></Link></li>*/}
                        {/*<li><Link><img src={Image.Youtube} alt=""/></Link></li>*/}
                    </ul>
                    <div>
                        <p>All right reserved by layf.az ©</p>
                    </div>
                    <div>
                        <p>Developed with by <a className={'text-white nav-link d-inline  pl-0'} href="https://vac.az/" target={'_blank'}>Virtual Azerbaijan Group of Companies</a></p>
                    </div>
                </div>
                <div  onClick={() => scrollToTop()} className="footer-leaf">
                    <span>Back To Top</span>
                </div>
            </div>
            }
        </div>
    );
}

const mapStateToProps = ({contact , socials}) => {
    return {contact , socials};
};

export default connect(mapStateToProps, {getContact , getSocials})(Footer);




