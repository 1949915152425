export const az = {
    rules: {
        rulesTitle: 'İstifadəçi şərtləri',
        letter1: 'Bu Gizlilik Siyasəti (bundan sonra – Gizlilik Siyasəti) https://layf.az domen adına yerləşən bir onlayn resurs sayt, onun proqramları və məhsullarını istifadə edərkən İstifadəçi haqqında ala biləcək bütün məlumatlara tətbiq edilir.',
        letter2: '1. ŞƏRTLƏRİN TANIMI:',
        letter3: '1.1 Bu Məxfilik Siyasətində aşağıdakı şərtlər istifadə olunur:',
        letter4: '1.1.1. “(Bundan sonra – Bu saytdan İdarəsi) Bu saytdan İdarəsi” – site idarə olunması üzrə səlahiyyətli personalı, https://layf.az adından fəaliyyət göstərən, təşkili və (və ya) fərdi məlumatların emal həyata keçirir və fərdi məlumatların şəxsi məlumatları emal, tərkibi məqsədlərini müəyyən emal ediləcək, şəxsi məlumatlarla həyata keçirilən əməliyyatlar (əməliyyatlar).',
        letter5: '1.1.2. “Şəxsi məlumatlar” bir şəxsin (fərdi məlumatların subyekti) birbaşa və ya dolayısı ilə təyin edilmiş və ya təyin etdiyi şəxslə əlaqəli hər hansı məlumatı bildirir.',
        letter6: '1.1.3. “Fərdi məlumatların emalı” – hər hansı bir hərəkət (əməliyyat) və ya hərəkətləri (əməliyyatlar) bir sıra avtomatlaşdırma avadanlıq istifadə və ya toplanması, qeyd, yığılması, saxlanması, aydınlaşdırılması, o cümlədən fərdi məlumatların, belə vasitələrdən istifadə etmədən həyata keçirilir (yeniləmə, dəyişiklik) şəxsi məlumatların çıxarılması, istifadəsi, köçürülməsi (paylanması, təmin edilməsi, əldə edilməsi), depersonalizasiyası, bloklanması, silinməsi, məhv edilməsi.',
        letter7: '1.1.4. “Şəxsi data gizlilik” – operator qarşılamaq üçün tələb olunan və ya şəxs tələb fərdi məlumatların başqa alınan giriş mövzu şəxsi məlumat və ya bazasının digər qanuni olması razılığı olmadan onların yayılmasının qarşısını almaq üçün.',
        letter8: '1.1.5. “Site istifadəçi yanında (İstifadəçi)” İnternet vasitəsilə Saytdan istifadə edən bir şəxs deməkdir.',
        letter9: '1.1.6. “Cookies” veb server tərəfindən göndərilən və veb-müştəri və ya veb-brauzerin müvafiq saytın səhifəsini açmaq üçün hər dəfə veb-serverə göndərdiyi istifadəçi kompüterində saxlanılan kiçik bir məlumatdır.',
        letter10: '1.1.7. “IP-ünvanı” IP protokolu əsasında qurulmuş kompüter şəbəkəsindəki nodeun unikal şəbəkə ünvanıdır.',
        letter11: '2. ÜMUMİ MÜDDƏALAR',
        letter12: '2.1. İstifadəçinin istifadə etdiyi resursdan istifadə edilməsi bu Gizlilik Siyasətinin qəbul edilməsini və İstifadəçinin şəxsi məlumatlarının işlənmə şərtlərini nəzərdə tutur.' ,
        letter13: '2.2. Gizlilik Siyasəti şərtləri ilə razılaşmaması halında, İstifadəçi sitədən (saytdan) istifadə etməməlidir.',
        letter14: '2.3. Əsl konfedensial siyasəti yalnız nəzarət və istifadəçi sitənin (saytın) mövcud saytın linkində istifadə bilir ki, üçüncü tərəf üçün məsuliyyət daşımır site yalnız https://layf.az aiddir.',
        letter15: '2.4. Saytın administrasiyası Sayt İstifadəçisinin verdiyi şəxsi məlumatların həqiqiliyini təsdiq etmir.',
        letter16: '3. GİZLİLİK SİYASƏTİ' ,
        letter17: '3.1. Bu Gizlilik Siyasəti Saytın qeyri-açıqlanması İdarəsinin vəzifələrini müəyyən edir və qeydiyyatd zamanı və ya ödəniş zaman istifadəçilər sitenin Administrator xahişi ilə təmin şəxsi məlumatların məxfiliyi rejimi qorunması təmin edir.',
        letter18: '3.2. Bu Gizlilik Siyasətinə əsasən işləmək üçün səlahiyyətli şəxsi məlumatlar Saytda qeydiyyat formasını dolduraraq İstifadəçi tərəfindən verilir və aşağıdakı məlumatları ehtiva edir:',
        letter19: '3.2.1. İstifadəçinin soyadı, adı, atasının adı;' ,
        letter20: '3.2.2. İstifadəçinin əlaqə telefonu;' ,
        letter21: '3.2.3. E-poçt ünvanı;' ,
        letter22: '3.3. Site, reklam birimlerinin görüntülenmesi ve sistemin statistik hesabının (“pixel”) yükləndiyi səhifələrə baxarkən avtomatik olaraq ötürülən məlumatları qoruyur:' ,
        letter23: '• IP ünvanı;' ,
        letter24: '• cookies-dən məlumat;' ,
        letter25: '3.3.1. Sayt istifadəçilərin IP-ünvanları haqqında statistika toplayır. Bu məlumat texniki problemləri müəyyən etmək və həll etmək, maliyyə ödənişlərinin qanuniliyini nəzarət etmək üçün istifadə olunur.' ,
        letter26: '3.4. Yuxarıda göstərilməmiş digər şəxsi məlumatlar (satınalma tarixi, brauzerlər və istifadə olunan əməliyyat sistemləri və s.) Təhlükəsiz saxlama və bölüşdürməməyə məruz qalır. Məxfilik Siyasəti 5.3 bəndində əksini tapır.' ,
        letter27:    '4. İSTİFADƏSİNİN ŞƏXSİ MƏLUMATININ TOPLANMASININ MƏQSƏDLƏRİ' ,
        letter28:    '4.1. İstifadəçinin şəxsi məlumatları Saytın administrasiyası aşağıdakı məqsədlər üçün istifadə edə bilər:' ,
        letter29:    '4.1.1. Sitedən qeydə alınmış İstifadəçinin ödəniş edə bilməsi üçün.' ,
        letter30:    '4.1.2. Saytın fərdi resurslarının İstifadəçiyə icazə verilməsi.' ,
        letter31:    '4.1.3. İstifadəçi tərəfindən bildirişlərin göndərilməsi, Saytın istifadəsinə, xidmət göstərilməsi, İstifadəçi istəklərinə və ərizələrinə aid istəklərə daxil olmaq.' ,
        letter32:    '4.1.4. İstifadəçinin istifadə yerinin təhlükəsizliyi, saxtakarlığın qarşısının alınması anlayışı.' ,
        letter33:    '4.1.5. İstifadəçinin verdiyi fərdi məlumatların həqiqiliyinin və tamlığının təsdiqlənməsi.' ,
        letter34:    '4.1.6. İstifadəçi bir hesab yaratmaq barədə razılığa gəldiyi təqdirdə ödəniş etmək üçün bir hesab yaratmaq.' ,
        letter35:    '4.1.7. Ödənişin vəziyyəti haqqında istifadəçiyə məlumatın verilməsi.' ,
        letter36:    '4.1.8. Ödənişlər xeyriyyə məqsədi ilə, ehtiyaci olan insanlara kömək üçün olunur və bu səbəbdən heç bir halda geri qaytarılmır.' ,
        letter37:    '4.1.9. İstifadəçiyə Saytın istifadəsi ilə əlaqədar problemlər halında effektiv müştəri və texniki dəstək göstərilməsi.' ,
        letter38:    '4.1.10. İstifadəçinin Razılaşması, saytın yeniləmələri, xüsusi təkliflər, Yeni xəbərlər haqqında məlumat, bülletenlər və Saytın adından və ya Saytın tərəfdaşları adından digər məlumatlarla təmin edilməsi.' ,
        letter39:    '4.1.11. İstifadəçinin razılığı ilə reklam fəaliyyətinin həyata keçirilməsi.' ,
        letter40:    '5. FƏRDİ İNFORMASİYANIN TOPLANILMAYOLLARI VE TƏTBİQİ.' ,
        letter41:    '5.1. İstifadəçinin fərdi məlumatlarının emalı heç bir müddət olmadan, o cümlədən avtomatlaşdırma vasitələrini istifadə edən və ya belə vasitələrdən istifadə etməyən şəxsi məlumat məlumat sistemləri sistemində qanuni yolla həyata keçirilir.' ,
        letter42:    '5.2. İstifadəçinin şəxsi məlumatı Azərbaycan dövlətinin səlahiyyətli orqanlarına yalnız Azərbaycan dövlətinin qanunvericiliyi ilə müəyyən edilmiş qaydada və qaydada verilə bilər.' ,
        letter43:    '5.3. Şəxsi məlumatların itirilməsi və ya açıqlanması halında, Sayt İdarəsi İstifadəçiyə fərdi məlumatların itirilməsi və ya açıqlanması barədə məlumat verir.' ,
        letter44:    '5.4. Sayt Administrasiyası icazəsiz və ya təsadüfi giriş, məhvedilmə, kopyalanma, bloklanılma, və üçüncü şəxslər tərəfindən digər qanunsuz hərəkətlərə qarşı İstifadəçi şəxsi məlumat qorumaq üçün lazımi təşkilati və texniki tədbirlər görür.' ,
        letter45:    '5.5. Saytın Administrasiyası İstifadəçi ilə birgə zərərin və ya İstifadəçinin şəxsi məlumatlarının itirilməsinin və ya açıqlanmasının səbəb olduğu digər mənfi nəticələrin qarşısını almaq üçün bütün lazımi tədbirləri görür.' ,
        letter46:    '6. TƏRƏFLƏRİN ÖHDƏLİKLƏRİ' ,
        letter47:    '6.1. İstifadəçinın öhdəliyi:' ,
        letter48:    '6.1.1. Saytdan istifadə etmək üçün lazım olan şəxsi məlumatlar barədə məlumat versin.' ,
        letter49:    '6.1.2. Bu məlumatın dəyişdirilməsi halında fərdi məlumatlarla təmin edilmiş məlumatları yeniləsin.' ,
        letter50:    '6.2. Saytın rəhbərliyin ohdəlikləri:' ,
        letter51:    '6.2.1. Bu Gizlilik Siyasətinin 4-cü bəndində göstərilən məqsədlər üçün yalnız alınan məlumatlardan istifadə etsin.' ,
        letter52:    '6.2.2. Məlumatın gizliliyini təmini ,İstifadəçi əvvəlcədən yazılı razılığı olmadan, açıqlamalı deyil, və 5.2 bəndi istisna olmaqla, mübadiləsi, nəşr, açıqlanması və ya İstifadəçi şəxsi məlumatlarını heç kəsə ötürüməli deyili.' ,
        letter53:    '6.2.3. İstifadəçilərin fərdi məlumatlarının gizli saxlanılması üçün mövcud tədbirlərdə bu cür məlumatları mühafizə etmək üçün istifadə olunan prosedura uyğun olaraq tədbirlər almalıdır.' ,
        letter54:    '6.2.4. İstifadəçilərə aid şəxsi məlumatların bloklaşması və ya saxta şəxsin məlumatının yayımlanmasının, təftiş dövr üçün şəxsi məlumatların subyektlərinin hüquqlarının müdafiəsi və İstifadəçinın haqlarının qorunmasını təmin etməlidir ' ,
        letter55:    '7. TƏRƏFLƏRİN SORUMLULUĞU' ,
        letter56:    '7.1. Saytın administrasiyası öz öhdəliklərini yerinə yetirmədiyi halda, Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq İstifadəçinin fərdi məlumatlarının istifadəsi ilə əlaqədar məsuliyyət daşıyır.' ,
        letter57:    '7.2. Gizli məlumatın məxviliyi və ya açıqlanması halında, bu məxfi məlumatlar olduqda Site İdarəsi məsuliyyət daşımır:' ,
        letter58:    '7.2.1. Kamu mülkiyyəti və ya açıqlanması daha əvvəl olduğu halda.' ,
        letter59:    '7.2.2. Sayt İdarəsinə təqdim olunmaqdan əvvəl, üçüncü şəxslərə bildirildikdə .' ,
        letter60:    '7.2.3. İstifadəçinin razılığı ilə açıqlandıqda.',
    },
    contact:{
        contact:"Əlaqə",
        wantToDonate:"Telefonla ianə etmək istəyirsiniz?",
        callUs:"Bizə zəng edin.",
        ourEmail:"Elektron poçt ünvanınız"
    },
    loadMore:"Daha çox yükləyin",
}
