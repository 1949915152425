import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {getSocials} from "../../../redux/actions";
import {connect} from "react-redux";

function Social(props) {
    const {socials, getSocials} = props
    useEffect(() => {
        if (socials.length === 0) {
            getSocials();
        }
    }, [])
    return (
        <div>
            <div className="social-icons">
                <ul>
                    {
                        socials.map((s,i) => (
                            <li><a target="_blank" href={s.link}><img src={s.photo.url} alt=""/></a></li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
}

const mapStateToProps = ({socials}) => {
    return {socials}
}

export default connect(mapStateToProps, {getSocials})(Social);
