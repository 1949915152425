import React from 'react';
import {useTranslation} from "react-i18next";

function Rules(props) {
    const {t} = useTranslation()
    return (
        <div className="our-mission position-relative animated fadeIn">
            <div className="container-fluid">
                <div className="section-text mt-5 pt-5 animated fadeInLeft">
                    <h2>{t('rules.rulesTitle')}</h2>
                </div>
                <div className='rule'>
                    <p className="mb-2">{t('rules.letter1')}</p>
                    <p className="mb-2">{t('rules.letter2')}</p>
                    <p className="mb-2">{t('rules.letter3')}</p>
                    <p className="mb-2">{t('rules.letter4')}</p>
                    <p className="mb-2">{t('rules.letter5')}</p>
                    <p className="mb-2">{t('rules.letter6')}</p>
                    <p className="mb-2">{t('rules.letter7')}</p>
                    <p className="mb-2">{t('rules.letter8')}</p>
                    <p className="mb-2">{t('rules.letter9')}</p>
                    <p className="mb-2">{t('rules.letter10')}</p>
                    <p className="mb-2">{t('rules.letter11')}</p>
                    <p className="mb-2">{t('rules.letter12')}</p>
                    <p className="mb-2">{t('rules.letter13')}</p>
                    <p className="mb-2">{t('rules.letter14')}</p>
                    <p className="mb-2">{t('rules.letter15')}</p>
                    <p className="mb-2">{t('rules.letter16')}</p>
                    <p className="mb-2">{t('rules.letter17')}</p>
                    <p className="mb-2">{t('rules.letter18')}</p>
                    <p className="mb-2">{t('rules.letter19')}</p>
                    <p className="mb-2">{t('rules.letter20')}</p>
                    <p className="mb-2">{t('rules.letter21')}</p>
                    <p className="mb-2">{t('rules.letter22')}</p>
                    <p className="mb-2">{t('rules.letter23')}</p>
                    <p className="mb-2">{t('rules.letter24')}</p>
                    <p className="mb-2">{t('rules.letter25')}</p>
                    <p className="mb-2">{t('rules.letter26')}</p>
                    <p className="mb-2">{t('rules.letter27')}</p>
                    <p className="mb-2">{t('rules.letter28')}</p>
                    <p className="mb-2">{t('rules.letter29')}</p>
                    <p className="mb-2">{t('rules.letter30')}</p>
                    <p className="mb-2">{t('rules.letter31')}</p>
                    <p className="mb-2">{t('rules.letter32')}</p>
                    <p className="mb-2">{t('rules.letter33')}</p>
                    <p className="mb-2">{t('rules.letter34')}</p>
                    <p className="mb-2">{t('rules.letter35')}</p>
                    <p className="mb-2">{t('rules.letter36')}</p>
                    <p className="mb-2">{t('rules.letter37')}</p>
                    <p className="mb-2">{t('rules.letter38')}</p>
                    <p className="mb-2">{t('rules.letter39')}</p>
                    <p className="mb-2">{t('rules.letter40')}</p>
                    <p className="mb-2">{t('rules.letter41')}</p>
                    <p className="mb-2">{t('rules.letter42')}</p>
                    <p className="mb-2">{t('rules.letter43')}</p>
                    <p className="mb-2">{t('rules.letter44')}</p>
                    <p className="mb-2">{t('rules.letter45')}</p>
                    <p className="mb-2">{t('rules.letter46')}</p>
                    <p className="mb-2">{t('rules.letter47')}</p>
                    <p className="mb-2">{t('rules.letter48')}</p>
                    <p className="mb-2">{t('rules.letter49')}</p>
                    <p className="mb-2">{t('rules.letter50')}</p>
                    <p className="mb-2">{t('rules.letter51')}</p>
                    <p className="mb-2">{t('rules.letter52')}</p>
                    <p className="mb-2">{t('rules.letter53')}</p>
                    <p className="mb-2">{t('rules.letter54')}</p>
                    <p className="mb-2">{t('rules.letter55')}</p>
                    <p className="mb-2">{t('rules.letter56')}</p>
                    <p className="mb-2">{t('rules.letter57')}</p>
                    <p className="mb-2">{t('rules.letter58')}</p>
                    <p className="mb-2">{t('rules.letter59')}</p>
                    <p className="mb-2">{t('rules.letter60')}</p>
                </div>
            </div>
        </div>
    );
}

export default Rules;
