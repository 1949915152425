import React, {useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {connect} from "react-redux";
import {getFreeSupportFull} from "../../../redux/actions";
import Images from "../../../assets/img/Images";

function FreeSupportFull(props) {
    const {freeSupportFull, getFreeSupportFull} = props
    const { id } = props.match.params;
    useEffect(() => {
        getFreeSupportFull(id);
    }, [id]);
    const productSlider = useRef();
    const settings = {
        dots: false,
        autoplay: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };
    const next = () => {
        productSlider.current.slickNext();
    }
    const previous = () => {
        productSlider.current.slickPrev();
    }
    return (
        <div>
            <div className="full-gallery main position-relative">
                <div className="container-fluid">
                    <div className="d-flex justify-content-end cancel">
                        <Link to="/free-get">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40.001" height="40" viewBox="0 0 40.001 40">
                                <path
                                    d="M23.665,20.136,39.241,4.56A2.591,2.591,0,0,0,35.576.9L20,16.472,4.425.9A2.591,2.591,0,0,0,.76,4.56L16.336,20.136.76,35.712a2.591,2.591,0,1,0,3.664,3.664L20,23.8,35.576,39.377a2.591,2.591,0,0,0,3.664-3.664Zm0,0"
                                    transform="translate(0 -0.136)" fill="#4DAF42"/>
                            </svg>
                        </Link>
                    </div>
                    <div
                        className="row  align-items-center full-gallery-content animated zoomIn">
                        <div className="pl-5 col-lg-6 col-12 text-lg-right text-center">
                            {
                                freeSupportFull?.images?.length > 1 &&
                                <div className="arrows">
                                    <div onClick={()=>{previous()}} >
                                        <div>
                                            <img src={Images.NextArrow} alt="" className="back" />
                                        </div>
                                    </div>
                                    <div  >
                                        <div onClick={()=>{next()}}>
                                            <img src={Images.NextArrow} alt="" className="next"/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                freeSupportFull?.images?.length > 1 ?
                                    <Slider
                                        ref={slider => (productSlider.current = slider)}
                                        {...settings}>
                                        {
                                            freeSupportFull?.images?.map((p,i) => (
                                                <img src={p.url} alt=""/>
                                            ))
                                        }
                                    </Slider>   :
                                    <div>
                                        {
                                            freeSupportFull?.images?.map((p,i) => (
                                                <img src={p.url} alt=""/>
                                            ))
                                        }
                                    </div>
                            }

                        </div>
                        <div className="col-lg-6 col-12  text-lg-center text-left">
                            <h6>{freeSupportFull.nameProduct}</h6>
                            {/*<span>Tarix : 2019-09-03</span>*/}
                            <p>{freeSupportFull.note}</p>
                            {/*<Link to="/apply-form">Götürmək istəyirəm</Link>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ freeSupportFull }) => {
    return { freeSupportFull };
};

export default connect(mapStateToProps, { getFreeSupportFull })(FreeSupportFull);
