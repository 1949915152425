import React, {useEffect, useState} from 'react';
import Image from "../../../assets/img/Images";
import {Link} from "react-router-dom";
// import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar'
import history from "../../../const/history";
import Nav from "react-bootstrap/Nav";
// import Badge from '@material-ui/core/Badge';
import Lang from '../../Elements/Lang/Lang';

function Header(props) {
    const [showMenu, setShow] = useState(true)
    const [showMenuTwo, setShowTwo] = useState(true)
    const [showMenuThree, setShowThree] = useState(false)
    const [scroll, setScroll] = useState(false)
    const [expanded, setExpanded] = useState(false);

  


    useEffect(() => {
        if (window.location.hash === "#/free-donate" || window.location.hash === '#/apply-form' || window.location.hash.includes('#/full-free-support') || window.location.hash.includes('#/full-staff')) {
            setShowTwo(false);
        } else {
            setShowTwo(true)
        }
        if (window.location.hash === '#/payment' || window.location.hash === '#/thank-payment') {
            setShow(false)
        } else {
            setShow(true)
        }
        if (window.location.hash === '#/payment' || window.location.hash === '#/thank-payment') {
            setShowThree(true)
        } else {
            setShowThree(false)
        }


        history.listen((location) => {
            if (location.hash === '#/payment' || location.hash === '#/thank-payment') {
                setShow(false)
            } else {
                setShow(true)
            }
            if (location.hash === '#/apply-form' || window.location.hash.includes('/full-free-support') || window.location.hash.includes('/full-staff') || location.hash === '#/free-donate') {
                setShowTwo(false)
            } else {
                setShowTwo(true)
            }

            if (location.hash === '#/payment' || location.hash === '#/thank-payment') {
                setShowThree(true)
            } else {
                setShowThree(false)
            }

        });

    }, [])

    const scrollFunc = () => {
        window.onscroll = function () {
            if (window.pageYOffset >= 10) {
                setScroll(true)
            } else {
                setScroll(false)
            }
        };
    }
    useEffect(() => {
        scrollFunc()
    })
    return (
        <div className="position-relative">
            <div className="container-fluid">
                {showMenuTwo &&
                <Navbar expanded={expanded} expand="xl"  className={`d-flex align-items-center header ${scroll ? 'active' : ''}  `}>
                    <Navbar.Brand>
                        <Link className="navbar-brand" to="/">
                            <img src={Image.Logo} alt=""/>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-xl-auto">
                            <div className="d-xl-flex align-items-center justify-content-between w-100">
                                {showMenu &&
                                <div className="navbar-nav">
                                    <div className="header-link my-xl-0 my-5">
                                        <ul className="d-xl-flex p-0 m-0">
                                            <li className="line"><Link to="/" onClick={() => setExpanded(false)} >Ana səhifə</Link></li>
                                            <li className="line"><Link to="/about" onClick={() => setExpanded(false)}>Haqqımızda</Link></li>
                                            <li className="line"><Link to="/media" onClick={() => setExpanded(false)}>Media</Link></li>
                                            {/* <li className="line"><Link to="/support-layf">Layfa dəstək</Link></li>*/}
                                            <li className="line"><Link to="/donates" onClick={() => setExpanded(false)}>Hesabat</Link></li>

                                            {/*<li className="line"><Link to="/make-dream">Möcuzə yarat</Link></li> */}
                                            <li className="line"><Link to="/contact" onClick={() => setExpanded(false)}>Əlaqə</Link></li>

                                        </ul>
                                    </div>
                                </div>
                                }

                                <div className='d-flex align-items-center justify-content-center'>
                                <Lang/>
                                <div className="d-xl-flex d-inline-block justify-content-around align-items-center">
                                    {/* <div className="mx-3 header-img my-xl-0 my-3">
                                        <Badge color="success" badgeContent={7}>
                                            <img src={Image.Bag} alt=""/>
                                        </Badge>
                                    </div> */}
                                    {/* <div className="header-img ml-3 my-xl-0 my-3">
                                        <Link to="#">
                                            <img src={Image.User} alt=""/>
                                        </Link>
                                    </div> */}
                                </div>

                                </div>

                                {!showMenu &&
                                    <div className="ml-5">
                                        <Link to="/">
                                            <img src={Image.Cancel} alt=""/>
                                        </Link>
                                    </div>
                                }
                                {!showMenuThree &&
                                <div className="donate my-xl-0 my-3 mx-xl-2 mx-lg-4">
                                    <Link to="/apply-form">
                                        <p className="mb-0 text-center">Müraciət forması</p>
                                    </Link>
                                </div>
                                }
                            </div>
                        </Nav>
                    </Navbar.Collapse>

                    <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="basic-navbar-nav"/>
                </Navbar>
                }
            </div>
        </div>
    );
}

export default Header;
