import React, {useRef, useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import InputMask from "react-input-mask";
import {ProgressBar} from "react-bootstrap";
import vys from "../../../const/api";
import history from "../../../const/history";


function FreeDonate(props) {
    const [FullName, setFullName ] = useState('');
    const [nameError, setNameError] = useState(false);
    const [NameProduct, setNameProduct ] = useState('');
    const [nameProductError, setNameProductError] = useState(false);
    const [Address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [Phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [Note, setNote] = useState('');
    const [NoteError, setNoteError] = useState(false);
    const [fileList , setFileList] = useState([]);
    const [showSuccess, setshow] = useState(false);
    const [progress, setProgress] = useState(0);

    const fileRef = useRef()

    const showmessage = (set) => {
        set(true)
        setTimeout(function () {
            set(false)
        }, 2000);
    }
    const inputs = [
        { n: FullName, error: nameError, setError: setNameError, setValue: setFullName, placeholder: 'Ad, Soyad Ata adı *', type: 'text' },
        { n: NameProduct, error: nameProductError, setError: setNameProductError, setValue: setNameProduct, placeholder: 'Məhsulun adı *', type: 'text' },
        { n: Address, error: addressError, setError: setAddressError, setValue: setAddress, placeholder: 'Ünvan *', type: 'text' }
    ]
    const inputPhone = [
        { n: Phone, error: phoneError, setError: setPhoneError, setValue: setPhone, placeholder: 'Telefon nömrəsi *', type: 'phone' },
    ]
    const inputNote = [
        { n: Note, error: NoteError, setError: setNoteError, setValue: setNote, placeholder: 'Qeyd', type: 'text' },
    ]
    const inputValidation = () => {
        let sendM = false
        inputs.map((i) => {
            if (i.n.trim() === null || i.n.trim() === "" || i.n === " ") {
                showmessage(i.setError)
                sendM = false
            } else {
                sendM = true
            }
        })
        inputPhone.map((input2) => {
            if (input2.n.trim() === null || input2.n.trim() === "" || input2.n === " ") {
                showmessage(input2.setError)
                sendM = false
            } else {
                sendM = true
            }
        })
        sendMessage(sendM)
    }
    const onsubmit = (e) => {
        e.preventDefault()
        inputValidation()
    }
    const sendMessage = (sendM) => {
        if (sendM) {
            vys
                .post("FreeSupport", {
                    FullName,
                    NameProduct,
                    Phone,
                    Address,
                    Note,
                    images: fileList.filter(fileLists => fileLists.type.startsWith('image')),
                    // subjectId: Number(subjectId),
                })
                .then((res) => {
                    showmessage(setshow)
                    setFullName('');
                    setNameProduct('')
                    setPhone('');
                    setAddress('');
                    setNote('');
                    deleteFile()
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }
    const deleteFile = (i) => {
        console.log(i)
        if(i || i === 0){
          let arr = [...fileList]
          arr.splice(i , 1)
          setFileList(arr)
        }
        else{
              setFileList([])
        }
    }
    function checkSizeOk(bytes) {
        const megabytes = bytes / (1024 * 1024);
        console.log(megabytes <= 1)
        return megabytes<=1;
    }


    const onUpload = e => {
      
        const index = fileList.length;
        let inserted = false;
        let form_data = new FormData();
        let uploadedFiles = [...e.target.files]
        const files = [...fileList];
        uploadedFiles.forEach((f)=>{
            if(f.type.startsWith('image') || f.type.startsWith('video')){
                files.push(
                    {
                        uploadedFile: false,
                        sizeOk: checkSizeOk(f.size)
                    }
                );
            }
        })
        for (let i = 0; i < files.length; i++) {
            if(files[i].sizeOk){
                form_data.append('files', uploadedFiles[i]);
            }
        }
        setFileList(files);
        console.log(files)
        vys
            .post("Upload/Files", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },

                onUploadProgress: data => {
                    const percent = Math.round(90 * data.loaded / data.total);
                    setProgress(percent);
                },
            })
            .then((res) => {
                const arr = [...fileList];
                let result = res.data.map((f)=>{
                    return {
                        ...f,
                        uploadedFile: true,
                        sizeOk: true
                    }
                })
                arr.splice(index, result.length, ...result);
                setProgress(0);
                setFileList(arr);
                console.log("uppp")
            })
            .catch((err) => {
                console.log(err)
            });
    }



    const resetFiles = () => {
    
        fileRef.current.value = null
    }

    return (
        <div>
            <div className="section-contact main position-relative">
                <div className="container-fluid">
                    <div className="d-flex justify-content-end cancel">
                        <Link to="/">
                            <img src={Image.Close} alt=""/>
                        </Link>
                    </div>
                    <div className="text-center contact-text">
                       {/*<div>*/}
                       {/*    <p>Pulsuz ver</p>*/}
                       {/*     <span>*/}
                       {/*         Pulsuz ver – Burada Siz istənilən əşyaların (məişət əşyaları, paltar, ayaqqabı, oyuncaq və sair …)*/}
                       {/*         şəkillərini yerləşdirərib və müvafiq məlumat yazaraaq onları eytiyac sahiblərinə təmanasız olaraq verə bilərsiniz.*/}
                       {/*     </span>*/}
                       {/*</div>*/}
                        <>
                            <form onSubmit={onsubmit}>
                                {showSuccess ? <h1 className="text-white animated fadeIn"><strong>Müraciətiniz qəbul olundu, sizə tez bir zamanda cavab veriləcək.</strong></h1> : <h1><strong>Pulsuz ver</strong></h1>}
                                <span>
                                   Pulsuz ver – Burada Siz istənilən əşyaların (məişət əşyaları, paltar, ayaqqabı, oyuncaq və sair…) şəkillərini yerləşdirib və müvafiq məlumatlarını qeyd edərək, onları ehtiyac sahiblərinə təmənnasız verə bilərsiniz. Şəkillərin çəkisi 1 Mb – dan çox olmasın .
                                </span>
                                <div>
                                    {inputs.map((inputs) => (
                                        <div className="position-relative">
                                            <div>
                                                <input placeholder={inputs.placeholder} value={inputs.n} className={`${inputs.error ? 'error' : 'mb-3'}`} type={inputs.type} onChange={(e) =>
                                                {inputs.setValue(e.target.value)}} />
                                                {inputs.error &&
                                                    <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                    {inputPhone.map((inputPhone) => (
                                        <div className="position-relative">
                                            <div>
                                                <InputMask placeholder={inputPhone.placeholder} {...props} mask="+\9\9\4 99 999 99 99" maskChar="" value={inputPhone.n} className={`${inputPhone.error ? 'error' : 'mb-3'}`} type={inputPhone.type} onChange={(e) =>
                                                {inputPhone.setValue(e.target.value)}} />
                                                {inputPhone.error &&
                                                    <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                    {inputNote.map((inputs) => (
                                        <div className="position-relative">
                                            <div>
                                                <input placeholder={inputs.placeholder} value={inputs.n} className={`${inputs.error ? 'error' : 'mb-3'}`} type={inputs.type} onChange={(e) =>
                                                {inputs.setValue(e.target.value)}} />
                                                {inputs.error &&
                                                    <span className="animated fadeIn error-text text-danger">Xana doldurulmalıdır!</span>}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="contact-link d-md-flex justify-content-center">
                                    <label className="upload-file mb-md-0 mb-3" onClick={resetFiles} htmlFor="upload-photo">Şəkil əlavə etmək</label>
                                    <input ref={fileRef} onChange={onUpload} type="file" name="cv" multiple id="upload-photo" />
                                    <button type='submit' >Göndər</button>
                                </div>
                                <div className="file-upload-box mt-4">
                                    {fileList.length > 0 &&
                                        fileList.map((f, i)=>(
                                            <React.Fragment key={i}>
                                                {f.sizeOk ? '' : <p style={{fontSize:'15px'}} className="mt-1 text-white mb-1">
                                                    Faylın ölçüsü 2Mb dan çoxdur..
                                                </p>}
                                                <div style={{
                                                    border : '3px solid',
                                                    borderColor: f.sizeOk ? 'transparent' : 'red',
                                                    margin:'20px',
                                                }} className="animated zoomIn progress-file d-flex justify-content-between align-items-center p-3 my-2">
                                                  

                                                  <div  style={{
                                                   position: 'relative',

                        
                                                }}>
                                                  <div className="uploaded-file mr-4 ">
                                                        {f.uploadedFile ? <img src={f.url} alt=""/> : <img src={Image.Avatar} alt=""/>}
                                                    </div>
                                                 
                                                    <div onClick={()=>{deleteFile(i)}} className="delete-file">
                                                        <div>
                                                            <img src={Image.Close} alt="" />
                                                        </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </form>
                        </>



                    </div>
                </div>
            </div>
        </div>
    );
}

export default FreeDonate;
