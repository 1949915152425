export const ru = {
    rules: {
        rulesTitle: 'Условия использования',
        letter1: 'Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую онлайн ресурс расположенный на доменном имени https://layf.az, может получить о Пользователе во время использования сайта, его программ и продуктов.',
        letter2: '1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ',
        letter3: '1.1 В настоящей Политике конфиденциальности используются следующие термины:',
        letter4: '1.1.1. «Администрация сайта (далее – Администрация сайта) » – уполномоченные сотрудники на управления сайтом, действующие от имени https://layf.az, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.',
        letter5: '1.1.2. «Персональные данные» — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).',
        letter6: '1.1.3. «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.',
        letter7: '1.1.4. «Конфиденциальность персональных данных» — обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.',
        letter8: '1.1.5. «Пользователь сайта далее (Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет.',
        letter9: '1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.',
        letter10: '1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.',
        letter11: '2. ОБЩИЕ ПОЛОЖЕНИЯ',
        letter12: '2.1. Использование Пользователем ресурса означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.' ,
        letter13: '2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта.',
        letter14: '2.3.Настоящая Политика конфиденциальности применяется только к сайту https://layf.az, который не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте.',
        letter15: '2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем Сайта.',
        letter16: '3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ' ,
        letter17: '3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации.',
        letter18: '3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте и включают в себя следующую информацию:',
        letter19: '3.2.1. фамилию, имя, отчество Пользователя;' ,
        letter20: '3.2.2. контактный телефон Пользователя;' ,
        letter21: '3.2.3. адрес электронной почты (e–mail);' ,
        letter22: '3.3. Сайт защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы («пиксель»):' ,
        letter23: '• IP адрес;' ,
        letter24: '• информация из cookies;' ,
        letter25: '3.3.1. Сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.' ,
        letter26: '3.4. Любая иная персональная информация неоговоренная выше (история платежей, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. настоящей Политики конфиденциальности.' ,
        letter27: '4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ' ,
        letter28: '4.1. Персональные данные Пользователя Администрация сайта может использовать в целях:' ,
        letter29: '4.1.1. Идентификации Пользователя, зарегистрированного на сайте, для осуществления оплаты.' ,
        letter30: '4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.' ,
        letter31:    '4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработка запросов и заявок от Пользователя.' ,
        letter32:    '4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.' ,
        letter33:    '4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.' ,
        letter34:    '4.1.6. Создания учетной записи для платежей, если Пользователь дал согласие на создание учетной записи.' ,
        letter35:    '4.1.7. Уведомления Пользователя Сайта о состоянии оплаты.' ,
        letter36:    '4.1.8. Оплата не подлежит возврату, так как делается в целях благотворительности, что бы помочь людям.' ,
        letter37:    '4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта.' ,
        letter38:    '4.1.10. Предоставления Пользователю с его согласия, обновления происходящие на сайте, новостной рассылки и иных сведений от имени Сайта или от имени партнеров Сайта.' ,
        letter39:    '4.1.11. Осуществления рекламной деятельности с согласия Пользователя.' ,
        letter40:    '5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ' ,
        letter41:    '5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.' ,
        letter42:    '5.2. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Азербайджана только по основаниям и в порядке, установленным законодательством Азербайджана' ,
        letter43:    '5.3. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.' ,
        letter44:    '5.4. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.' ,
        letter45:    '5.5. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.' ,
        letter46:    '6. ОБЯЗАТЕЛЬСТВА СТОРОН' ,
        letter47:    '6.1. Пользователь обязан:' ,
        letter48:    '6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.' ,
        letter49:    '6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.' ,
        letter50:    '6.2. Администрация сайта обязана:' ,
        letter51:    '6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.' ,
        letter52:    '6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не платёж, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. настоящей Политики Конфиденциальности.' ,
        letter53:    '6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.' ,
        letter54:    '6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.' ,
        letter55:    '7. ОТВЕТСТВЕННОСТЬ СТОРОН' ,
        letter56:    '7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Азербайджанской Республики.' ,
        letter57:    '7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:\n' ,
        letter58:    '7.2.1. Стала публичным достоянием до её утраты или разглашения.' ,
        letter59:    '7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.' ,
        letter60:    '7.2.3. Была разглашена с согласия Пользователя.',
    },
    contact:{
        contact:"Контакт",
        wantToDonate:"Хотите сделать пожертвование по телефону?",
        callUs:"Позвоните нам сейчас",
        ourEmail:"Или напишите нам"
    },
    loadMore:"Загрузить больше причин",
}
