import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import siteUrl from "../../../const/url";
import { useTranslation } from 'react-i18next';

function Media(props) {
    const {t} = useTranslation()
    const perPage = 4;
    const [page, setPage] = useState(0);
    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getMediaList = () => {
            setLoading(true);
            fetch(`${siteUrl}api/News?PerDataCount=${page}&GetDataCount=${perPage}`, {
                headers : {
                    "accept-language" : localStorage.getItem("locale")
                        ? localStorage.getItem("locale")
                        : "az"
                }
            })
                .then(res => res.json())
                .then(res => {
                    setMedia([...media, ...res ]);
                    setLoading(false);
                });
        }
        getMediaList();
    }, [page]);

    const category = [
        {
            name: "Xəbərlər",
            type: 1,
        },
        // {
        //     name: "Bitmiş müraciətlər",
        //     type: 2,
        // },
        // {
        //     name: "Bitmiş layihələr",
        //     type: 3,
        // }
    ]
    return (
        <div>
            <div className="main position-relative">
                <div className="container-fluid">
                    <div className="row justify-content-between mt-5 align-items-center">
                        <div className="section-text mt-5 animated zoomIn col-lg-6">
                            <h2>Media</h2>
                        </div>
                  <div className="support-button">
                  <div className="support-links  ">
                            <ul className=" bg-white  media-border">
                                {
                                    category.map((c,i) => (
                                        <li key={i} className="line-link media mt-1">{c.name}</li>
                                    ))
                                }

                            </ul>
                        </div>
                    </div>
                    </div>
                    <div className="support-gallery animated zoomIn">
                        <div className="row ">
                            {
                                media.map((n,i) => (
                                    <div className="col-xl-3 col-lg-4 col-12 mb-5">
                                        <div className="box" >
                                            <div>
                                                <img src={n.photo.url} alt=""/>
                                            </div>
                                            <div className="box-text">
                                                <span>{n.title}</span>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: n.content
                                                    }}
                                                />
                                            </div>
                                            <div className="box-link">
                                                <Link to={`/full-media/${n.id}`}><p>Ətraflı</p></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="links d-flex align-items-center justify-content-center mb-4">
                            { media.length  >= perPage ? <button className="btn-load-more" onClick={() => setPage(page + perPage)}>{loading ? 'Loading...' : t('loadMore')}</button> : " " }
                        </div>
                    </div>
                    <div className="payment-leaf1">
                        <img src={Image.Leaf2} alt=""/>
                    </div>
                    <div>
                        <img src={Image.OurCauses} alt="" className="donation"/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Media;
