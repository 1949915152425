import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Images from "../../assets/img/Images";


function PartnersModal({partners, show, onHide}) {
    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="public-modal">
                    <div onClick={onHide} className="close animated fadeInRightBig">
                        <img src={Images.BlackClose} alt=""/>
                    </div>
                    <div className="row p-4 animated zoomIn">
                        <div className="col-lg-4 col-sm-5">
                            <div className="modal-img position-relative">
                                <img src={partners?.image.url} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-8 col-sm-7">
                            <div className="modal-text mt-2">
                                <h4 className="line-black mb-2">{partners?.name}</h4>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: partners?.content
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}


export default PartnersModal;
