import React, {useEffect} from 'react';
import history from "../const/history";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import Routes from "./Elements/Routes/Routes";

function App() {
 
    return (
        <div>
            <Header/>
            <Routes/>
            <Footer/>
        </div>
    );
}
export default App
