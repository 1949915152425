import React from 'react';
import Image from "../../../assets/img/Images";
import {Link} from "react-router-dom";
function Payment(props) {
    return (
        <div>
            <div className="main h-100vh position-relative">
                <div className="container-fluid">
                    <div className="payment-social-icons">
                        <ul>
                            <li><Link><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M20,10A10,10,0,1,0,10,20c.059,0,.117,0,.176,0V12.215H8.027v-2.5h2.148V7.867a3.009,3.009,0,0,1,3.211-3.3,17.452,17.452,0,0,1,1.926.1V6.9H14c-1.035,0-1.238.492-1.238,1.215V9.707h2.48l-.324,2.5H12.762v7.4A10,10,0,0,0,20,10Z" fill="#929292"/></svg></Link></li>
                            <li><Link><svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.25" viewBox="0 0 20 16.25"><g transform="translate(0)"><path d="M20,49.924a8.548,8.548,0,0,1-2.363.647,4.077,4.077,0,0,0,1.8-2.266,8.194,8.194,0,0,1-2.6.993,4.1,4.1,0,0,0-7.092,2.8,4.222,4.222,0,0,0,.1.935,11.606,11.606,0,0,1-8.451-4.289,4.1,4.1,0,0,0,1.26,5.48,4.049,4.049,0,0,1-1.853-.5v.045A4.119,4.119,0,0,0,4.085,57.8a4.092,4.092,0,0,1-1.075.135,3.626,3.626,0,0,1-.776-.07,4.139,4.139,0,0,0,3.831,2.856A8.239,8.239,0,0,1,.981,62.466,7.681,7.681,0,0,1,0,62.41a11.543,11.543,0,0,0,6.29,1.84A11.59,11.59,0,0,0,17.96,52.583c0-.181-.006-.356-.015-.53A8.18,8.18,0,0,0,20,49.924Z" transform="translate(0 -48)" fill="#929292"/></g></svg></Link></li>
                            <li><Link><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M13.75,0H6.25A6.251,6.251,0,0,0,0,6.25v7.5A6.251,6.251,0,0,0,6.25,20h7.5A6.251,6.251,0,0,0,20,13.75V6.25A6.251,6.251,0,0,0,13.75,0Zm4.375,13.75a4.38,4.38,0,0,1-4.375,4.375H6.25A4.38,4.38,0,0,1,1.875,13.75V6.25A4.38,4.38,0,0,1,6.25,1.875h7.5A4.38,4.38,0,0,1,18.125,6.25Z" fill="#929292"/><g transform="translate(5 5)"><path d="M133,128a5,5,0,1,0,5,5A5,5,0,0,0,133,128Zm0,8.125A3.125,3.125,0,1,1,136.125,133,3.129,3.129,0,0,1,133,136.125Z" transform="translate(-128 -128)" fill="#929292"/></g><g transform="translate(14.709 3.959)"><circle cx="0.666" cy="0.666" r="0.666" fill="#929292"/></g></svg></Link></li>
                            <li><Link><svg xmlns="http://www.w3.org/2000/svg" width="20" height="14.003" viewBox="0 0 20 14.003"><path d="M19.589-3.891a2.506,2.506,0,0,0-1.763-1.763C16.261-6.082,10-6.082,10-6.082s-6.26,0-7.825.412A2.556,2.556,0,0,0,.413-3.891,26.4,26.4,0,0,0,0,.92,26.3,26.3,0,0,0,.413,5.73,2.506,2.506,0,0,0,2.175,7.493C3.757,7.921,10,7.921,10,7.921s6.26,0,7.825-.412a2.506,2.506,0,0,0,1.763-1.763A26.41,26.41,0,0,0,20,.936a25.061,25.061,0,0,0-.412-4.827ZM8.007,3.918v-6l5.206,3Zm0,0" transform="translate(-0.001 6.082)" fill="#929292"/></svg></Link></li>
                        </ul>
                    </div>
                    <div className="row h-100 mt-5 pt-5">
                        <div className="col-lg-5 col-12 animated fadeInLeftBig position-rel">
                            <div className="back-track">
                               <Link to="/donate-now">
                                   <img src={Image.BackTrack} alt=""/>
                                   <p>BACK</p>
                               </Link>
                            </div>
                            <div className="bank">
                                <img src={Image.PasaBank} alt=""/>
                            </div>
                            <div className="payment-price d-flex align-items-baseline">
                                <p>Məbləğ:</p>
                                <span className="d-flex align-items-baseline">15.00 <p>AZN</p></span>
                            </div>
                            <div className="payment-info">
                                <form action="">
                                   <div className="d-flex">
                                       <input type="text" placeholder="Ad Soyad"/>
                                       <input type="text" placeholder="Kartın nömrəsi"/>
                                   </div>
                                    <div className="d-flex">
                                        <input type="text" placeholder="Ad Soyad"/>
                                        <input className="cvv" type="password" placeholder="CVV"/>
                                    </div>
                                </form>
                            </div>
                            <div className="d-flex justify-content-between accept-payment align-items-baseline">
                                <div>
                                    <p>Məlumatları təsdiqləyin:</p>
                                </div>
                                <div>
                                    <Link to="/thank-payment">Təsdiq</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12 animated fadeInRightBig d-flex justify-content-end">
                            <div className="payment-card">
                                <img src={Image.Card} alt=""/>
                            </div>
                            <div className="payment-leaf1">
                                <img src={Image.Leaf2} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Payment;
