import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getWorkersFull} from "../../../redux/actions";

function FullStaff(props) {
    const {workersFull, getWorkersFull} = props
    const {id} = props.match.params
    useEffect( () => {
        getWorkersFull(id)
    }, [id])
    return (
        <div>
            <div className="full-gallery main position-relative">
                <div className="container-fluid">
                    <div className="d-flex justify-content-end cancel">
                        <Link to="/about">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40.001" height="40" viewBox="0 0 40.001 40"><path d="M23.665,20.136,39.241,4.56A2.591,2.591,0,0,0,35.576.9L20,16.472,4.425.9A2.591,2.591,0,0,0,.76,4.56L16.336,20.136.76,35.712a2.591,2.591,0,1,0,3.664,3.664L20,23.8,35.576,39.377a2.591,2.591,0,0,0,3.664-3.664Zm0,0" transform="translate(0 -0.136)" fill="#4DAF42"/></svg>
                        </Link>
                    </div>
                    <div className="row justify-content-center align-items-center full-staff-content animated zoomIn">
                        <div className="col-md-4 col-12">
                            <div className="profile-img ">
                                <img src={workersFull?.image?.url} alt=""/>
                            </div>
                        </div>
                        <div className="col-md-5 col-12">
                            <h6>{workersFull?.fullName}</h6>
                            <span><strong>Vəzifəsi:  </strong>  {workersFull?.profession}</span>
                            <p className='emp-info'
                                dangerouslySetInnerHTML={{
                                    __html: workersFull?.about
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({workersFull}) => {
    return {workersFull};
}

export default connect(mapStateToProps, {getWorkersFull})(FullStaff);
