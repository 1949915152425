import React, {useEffect} from 'react';
import Image from "../../../assets/img/Images";
import {connect} from "react-redux";
import {getNewsFull} from "../../../redux/actions";
// import {Link} from "react-router-dom";
// import Social from "../../Elements/Social/Social";

function FullMedia(props) {
    const {newsFull, getNewsFull } = props;
    const {id} = props.match.params
    useEffect( () => {
        getNewsFull(id)
    }, [id])

    return (
        <div>
            <div className="home main position-relative animated fadeIn">
                <div className="container-fluid">
                    <div className="row h-100 mt-5 pt-5 animated zoomIn mb-lg-0 mb-5">
                        <div className="col-lg-6 ">
                            <div className="section-text mt-3">
                                <h2>{newsFull?.title}</h2>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: newsFull?.content
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 position-relative animated fadeInRightBig d-flex justify-content-lg-end ">
                            <div className="mt-lg-5 mt-md-4 mt-3">
                                <img src={newsFull?.photo?.url} alt="" className="border-radius-20 w-100"/>
                            </div>
                        </div>
                    </div>
                    <div className="payment-leaf1">
                        <img src={Image.Leaf2} alt=""/>
                    </div>
                    <div>
                        <img src={Image.Yardimlar} alt="" className="donation"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({newsFull}) => {
    return {newsFull};
}

export default connect(mapStateToProps, {getNewsFull})(FullMedia);
