import Logo from './layf-logo.svg'
import LayfLogo from './layf-white.svg'
import Bag from './bag.svg'
import User from './user.svg'
import Leaf1 from './leaf1.svg'
import Leaf2 from './leaf2.svg'
import Leaf3 from './leaf3.svg'
import Aze from './aze.svg'
import Rus from './russia.svg'
import Eng from './united-kingdom.svg'
import Photo1 from './photo1.jpg'
import Photo2 from './photo2.jpg'
import Play from './play.svg'
import Children from './children.svg'
import Border from './border.svg'
import PlayIcon from './play-icon.svg'
import Photo3 from './photo3.svg'
import Photo4 from './photo4.svg'
import Photo5 from './photo5.svg'
import Photo6 from './photo6.svg'
import Photo7 from './photo7.jpg'
import Photo8 from './photo8.jpg'
import Photo9 from './photo9.jpg'
import Photo10 from './photo10.jpg'
import Photo11 from './photo11.jpg'
import Photo12 from './photo12.jpg'
import Photo13 from './photo13.jpg'
import Photo14 from './photo14.jpg'
import Photo15 from './photo15.jpg'
import Photo16 from './photo16.jpg'
import Photo17 from './photo17.jpg'
import Photo18 from './photo18.jpg'
import Photo19 from './photo19.jpg'
import Photo20 from './photo20.jpg'
import Photo21 from './photo21.jpg'
import Photo22 from './photo22.jpg'
import Photo23 from './photo23.svg'
import Roya from './roya.png'
import People from './people.jpg'
import Share from './share.svg'
import Back from './back.svg'
import Next from './next.svg'
import LeafWhite from './leaf-white.svg'
import Line from './line.svg'
import NextArrow from './next-arrow.svg'
import Facebook from './facebook (4).svg'
import Twitter from './twitter (3).svg'
import Instagram from './instagram (2).svg'
import Youtube from './youtube (1).svg'
import ProfilePic from './profile.jpg'
import Children1 from './children1.jpg'
import Children2 from './children2.jpg'
import Children3 from './children3.jpg'
import Children4 from './children4.jfif'
import Donation from './DONATION.png'
import Down from './down.svg'
import Girl from './girl.svg'
import Faq from './FAQ.png'
import Card from './card.png'
import PasaBank from './pasabank.svg'
import BackTrack from './back-track.svg'
import Cancel from './cancel.svg'
import Dot from './dot.svg'
import Hands from './hands.jfif'
import OurMission from './OUR MISSION.png'
import AboutUs from './ABOUT US.png'
import OurCauses from './OUR CAUSES.png'
import Close from './close.svg'
import Yardimlar from './Yardımlar.png'
import DonateLayf from './donateLayf.png'
import DonateHeart from './donateHeart.png'
import Gift from './gift.png'
import Emergency from './emergency.png'
import Avatar from './avatar.png'
import BlackClose from './blackClose.svg'

export default  {
    Logo,
    LayfLogo,
    Bag,
    User,
    Leaf1,
    Leaf2,
    Leaf3,
    Aze,
    Rus,
    Eng,
    Photo1,
    Photo2,
    Photo3,
    Photo4,
    Photo5,
    Photo6,
    Photo7,
    Photo8,
    Photo9,
    Photo10,
    Photo11,
    Photo12,
    Photo13,
    Photo14,
    Photo15,
    Photo16,
    Photo17,
    Photo18,
    Photo19,
    Photo20,
    Photo21,
    Photo22,
    Photo23,
    Play,
    Children,
    Border,
    PlayIcon,
    People,
    Share,
    Back,
    Next,
    LeafWhite,
    Roya,
    Line,
    NextArrow,
    Facebook,
    Twitter,
    Instagram,
    Youtube,
    ProfilePic,
    Children1,
    Children2,
    Children3,
    Children4,
    Donation,
    Down,
    Girl,
    Faq,
    Card,
    PasaBank,
    BackTrack,
    Cancel,
    Dot,
    Hands,
    OurMission,
    AboutUs,
    OurCauses,
    Close,
    Yardimlar,
    DonateLayf,
    DonateHeart,
    Gift,
    Emergency,
    Avatar,
    BlackClose,
}
