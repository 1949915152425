import types from "../types";
import layf from "../../const/api"


export const changeLanguage = (payload) => {
    return {
        type: types.SET_LANG,
        payload,
    };
};

export const getSubject = () => async (dispatch) => {
    layf
        .get("subject")
        .then((res) => {
            dispatch({
                type: types.GET_SUBJECT,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_SUBJECT_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getPartners = () => async (dispatch) => {
    layf
        .get("Partner")
        .then((res) => {
            dispatch({
                type: types.GET_PARTNERS,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_PARTNERS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getContact = () => async (dispatch) => {
    layf
        .get("Contact")
        .then((res) => {
            dispatch({
                type: types.GET_CONTACT,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_CONTACT_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getSocials = () => async (dispatch) => {
    layf
        .get("SosialNetwork")
        .then((res) => {
            dispatch({
                type: types.GET_SOCIALS,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_SOCIALS_ERROR,
                payload: { message: "Xəta baş verdi" },
            });
        });
};

export const getAbout = () => async (dispatch) => {
    layf
        .get('AboutUs')
        .then((res) => {
            dispatch({
                type: types.GET_ABOUT,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_ABOUT_ERROR,
                payload: { message: "Xəta baş verdi"},
            });
        });
}

export const getYears = () => async (dispatch) => {
    layf
        .get('ActionForYears')
        .then((res) => {
            dispatch({
                type: types.GET_YEARS,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_YEARS_ERROR,
                payload: { message: "Xəta baş verdi"},
            });
        });
}


// export const getSupport = () => async (dispatch) => {
//     layf
//
//         .get('GetSupport', {params: {GetDataCount: 4}})
//         // .get('News',{params:{newsType : 1 }})
//         .then((res) => {
//             dispatch({
//                 type: types.GET_SUPPORT,
//                 payload: res.data
//             });
//         })
//         .catch((err) => {
//             dispatch({
//                 type: types.GET_SUPPORT_ERROR,
//                 payload: { message: "Xəta baş verdi"},
//             });
//         });
// }

export const getSupportFull = (id) => async (dispatch) => {
    layf
        .get(`GetSupport/Details/${id}`)
        .then((res) => {
            dispatch({
                type: types.GET_SUPPORT_FULL,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_SUPPORT_FULL_ERROR,
                payload: { message: "Xəta baş verdi"},
            });
        });
}

export const getFreeSupportFull = (id) => async (dispatch) => {
    layf
        .get(`FreeSupport/Details/${id}`)
        .then((res) => {
            dispatch({
                type: types.GET_FREE_SUPPORT_FULL,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_FREE_SUPPORT_FULL_ERROR,
                payload: { message: "Xəta baş verdi"},
            });
        });
}

export const getNewsFull = (id) => async (dispatch) => {
    layf
        .get(`News/Details/${id}`)
        .then((res) => {
            dispatch({
                type: types.GET_NEWS_FULL,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_NEWS_FULL_ERROR,
                payload: { message: "Xəta baş verdi"},
            });
        });
}

export const getWorkersFull = (id) => async (dispatch) => {
    layf
        .get(`worker/details/${id}`)
        .then((res) => {
            dispatch({
                type: types.GET_WORKERS_FULL,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_WORKERS_FULL_ERROR,
                payload: { message: "Xəta baş verdi"},
            });
        });
}

export const getWorkers = () => async (dispatch) => {
    layf
        .get('worker')
        .then((res) => {
            dispatch({
                type: types.GET_WORKERS,
                payload: res.data
            });
        })
        .catch((err) => {
            dispatch({
                type: types.GET_WORKERS_ERROR,
                payload: { message: "Xəta baş verdi"},
            });
        });
}

