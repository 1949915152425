import React, { useEffect, useRef } from 'react';
import Image from "../../../assets/img/Images";
import { Link,  useLocation } from "react-router-dom";
import Slider from "react-slick";
import Social from "../../Elements/Social/Social";
import { getPartners, getAbout } from "../../../redux/actions";
import { connect } from "react-redux";

function Home(props) {
    const partnersSlider = useRef()
    const location = useLocation();

    const partnersSetting = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        draggable: true,
        rows: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 426,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const { partners, getPartners, about, getAbout } = props
    useEffect(() => {
        let mounted = true
        if (partners.length === 0) {
            getPartners();
        }
        if (about.length === 0){
            getAbout();
        }
        return function cleanup() {
            mounted = false
        }
    }, [partners, about])

//   useEffect(()=>{
//     const orderId = new URLSearchParams(location.search).get('order_id');
//     if(orderId){
//       console.log("id:", orderId)
     
//     }


//   },[location.search]) 

    return (
        <div>
            <div className="home main position-relative">
                <div className="container-fluid">
                    <div className="row h-100 mt-5 pt-5">
                        <div className="col-xl-6 col-12 animated fadeInLeftBig">
                            <div className="main-card ">
                                <div className="d-md-flex d-block">
                                    <Link to={'/support-layf'}>
                                        <img src={Image.DonateLayf} alt="" />
                                        <p>Dəstək ver</p>
                                    </Link>
                                    <Link to={'/apply-form'}>
                                        <img src={Image.Emergency} alt="" />
                                        <p>Dəstək al</p>
                                    </Link>
                                </div>
                                <div className="d-md-flex d-block">
                                    <Link to={'/free-donate'}>
                                        <img src={Image.DonateHeart} alt="" />
                                        <p>Pulsuz ver</p>
                                    </Link>
                                    <Link to={'/free-get'}>
                                        <img src={Image.Gift} alt="" />
                                        <p>Pulsuz al</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="text-bold my-4 d-flex align-items-center justify-content-between">
                                <h3 className="mb-1">Daim bizimlə olan tərəfdaşlarımız</h3>
                                <Link to="/full-partners" className="cursor-pointer"><span>Ətraflı</span></Link>
                            </div>
                            {
                                partners.length < 3 ?
                                    <div className="d-flex mt-4 gap-30">
                                        {
                                            partners.map((p, i) => (
                                                <div key={i} className="partnersImg">
                                                    <img src={p.image.url} alt=""/>
                                                </div>
                                            ))
                                        }
                                    </div> :
                                    <Slider
                                        className='mainslider mt-4'
                                        ref={slider => (partnersSlider.current = slider)}
                                        {...partnersSetting}
                                    >
                                        {
                                            partners.map((p, i) => (
                                                <div key={i} className="partnersImg">
                                                    <img src={p.image.url} alt=""/>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                            }
                        </div>
                        <div className="col-xl-6 col-12 d-xl-block d-none section-one-leaf position-relative animated fadeInRightBig">
                            <div className="leaf">
                                <img src={Image.Children1} alt="" className="pic" />
                            </div>
                            {/* <div className="leafRight">
                                <img src={Image.Leaf2} alt="" />
                            </div> */}
                        </div>
                    </div>
                    {/*<Social/>*/}
                </div>
            </div>

            <div className="our-mission position-relative my-lg-2 my-3">
                <div className="container-fluid">
                    {
                        about.filter(a => {
                            return a === about[0]
                        }).map(a => (
                            <div className="row h-100">
                                <div className="col-xl-6 col-12 section-one-leaf d-xl-block d-none position-relative">
                                    <div className="leaf">
                                        <img src={a.photo.url} alt="" className="pic"/>
                                    </div>
                                    {/* <div className="leafLeft">
                                        <img src={Image.Leaf2} alt=""/>
                                    </div> */}
                                </div>
                                <div className="col-xl-6 col-12 mt-0 section-text">
                                    <div className="section-link mb-3">
                                        <Link to="/about" className="line">HAQQIMIZDA</Link>
                                    </div>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: a.about
                                        }}

                                    />
                                    <div className="section-two-links d-flex align-items-center float-right">
                                        {/* <Link><img src={Image.PlayIcon} alt="" /></Link> */}
                                        <Link to="/about">Daha ətraflı</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div>
                        <img src={Image.AboutUs} alt="" className="donation"/>
                    </div>
                </div>
            </div>
        </div>

    );
}

const mapStateToProps = ({ partners, about }) => {
    return { partners, about };
}

export default connect(mapStateToProps, { getPartners, getAbout })(Home);
