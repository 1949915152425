import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Image from "../../../assets/img/Images";
import { useTranslation } from "react-i18next";
import siteUrl from "../../../const/url";

function Donates(props) {
  const { t } = useTranslation();

  const perPage = 5;
  const [page, setPage] = useState(0);
  const [donateList, setDonateList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getSupportList = () => {
      setLoading(true);
      fetch(
        `${siteUrl}api/PaymentReport?PerDataCount=${page}&GetDataCount=${perPage}`,
        {
          headers: {
            "accept-language": localStorage.getItem("locale")
              ? localStorage.getItem("locale")
              : "az",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setDonateList([...donateList, ...res]);
          setLoading(false);
        });
    };
    getSupportList();
  }, [page]);
  console.log(donateList);
  return (
    <div>
      <div className="main position-relative">
        <div className="container-fluid">
          <div className="row justify-content-between mt-5 align-items-center">
            <div className="section-text mt-5 col-lg-6">
              <h2>Hesabat</h2>
            </div>
            {/* <div className="support-links col-lg-6 d-flex justify-content-end">
              <ul className="d-flex">
                <li className="line-link">Şirkət</li>
                <li className="line-link">Fərdi şəxs</li>
              </ul>
            </div> */}
          </div>
          {/* <div className="donates animated zoomIn">
            <div className="d-xl-flex d-block justify-content-between text-right gap-4">
              <div className="donate-card">
                <div className="animated fadeInLeftBig mx-2 mt-xl-0 mt-4 donates-board d-sm-flex text-center justify-content-between align-items-center">
                  <div className="d-sm-flex d-block mb-sm-0 mb-3 name">
                    <strong>Ad Soyad  </strong>
                  </div>
                  <div className="mb-sm-0 mb-3 amount">
                    <strong>Yardım məbləği</strong>
                  </div>
                  <div className="mb-sm-0 mb-3 ">
                    <strong>Qəbul edən</strong>
                  </div>
                </div>
              </div>
             
            </div>
          </div> */}
          <div className="donates animated zoomIn">
         
             <div className="report">
             <div className="report-card">
                <div className="donate-card mb-3">
                  <div className="animated mx-2 mt-xl-0 mt-4 donates-board d-flex text-center justify-content-between align-items-center">
                    <div className="d-sm-flex d-block mb-sm-0 mb-3 name">
                      <strong>Ad Soyad </strong>
                    </div>
                    <div className="mb-sm-0 mb-3 amount">
                      <strong>Yardım məbləği</strong>
                    </div>
                    <div className="mb-sm-0 mb-3 ">
                      <strong>Qəbul edən</strong>
                    </div>
                  </div>
                </div>
                <div className="donate-card">
                  {donateList.map(
                    (item, index) =>
                      item.type == 1 && (
                        <div className="animated  mx-2 mt-xl-0 mt-4 donates-board d-flex text-center justify-content-between align-items-center">
                          <div className="d-sm-flex d-block mb-sm-0 mb-3 name">
                            <span>
                              {item?.isanonymous ? "anonim" : item?.name}
                            </span>
                          </div>
                          <div className="mb-sm-0 mb-3 amount">
                            {/* <span>Yardım məbləğ</span> */}
                            <p className="price">{item?.amount}</p>
                          </div>
                          <div className="mb-sm-0 mb-3 supportname">
                            <p className="pr-5">
                              {item?.supportPersonFullName}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>

         
           
            </div>

            <div className="report-card">
            <div className="donate-card mb-3">
                <div className="animated  mx-2 mt-xl-0 mt-4 donates-board d-flex text-center justify-content-between align-items-center">
                  <div className="d-sm-flex d-block mb-sm-0 mb-3 name">
                    <strong>Şirkət adı</strong>
                  </div>
                  <div className="mb-sm-0 mb-3 amount">
                    <strong>Yardım məbləği</strong>
                  </div>
                  <div className="mb-sm-0 mb-3 ">
                    <strong> Qəbul edən </strong>
                  </div>
                </div>
              </div>
              <div className="donate-card">
                {donateList.map(
                  (item, index) =>
                    item.type == 2 && (
                      <div className="animated mx-2 mt-xl-0 mt-4 donates-board d-flex text-center justify-content-between align-items-center">
                        <div className="d-sm-flex d-block mb-sm-0 mb-3 name">
                          <span>
                            {item?.isanonymous ? "anonim" : item?.name}
                          </span>
                        </div>
                        <div className="mb-sm-0 mb-3 amount">
                          {/* <span>Yardım məbləğ</span> */}
                          <p className="price">{item?.amount}</p>
                        </div>
                        <div className="mb-sm-0 mb-3 supportname">
                          <p className="pr-5">{item?.supportPersonFullName}</p>
                        </div>
                      </div>
                    )
                )}
              </div>
         
           
            </div>

            

            
             </div>

            <div>
              <div className="links d-flex align-items-center justify-content-center mb-4">
                {donateList.length >= perPage ? (
                  <button
                    className="btn-load-more"
                    onClick={() => setPage(page + perPage)}
                  >
                    {loading ? "Loading..." : t("loadMore")}
                  </button>
                ) : (
                  " "
                )}
              </div>
            </div>
          </div>
          <div className="payment-leaf1 ">
            <img src={Image.Leaf2} alt="" />
          </div>
          <div>
            <img src={Image.Yardimlar} alt="" className="donation" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donates;
