import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import siteUrl from "../../../const/url";
import { useTranslation } from 'react-i18next';


function FreeGet(props) {
    const {t} =  useTranslation()
    const perPage = 4;
    const [page, setPage] = useState(0);
    const [freeList, setFreeList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getFreeList = () => {
            setLoading(true);
            fetch(`${siteUrl}api/FreeSupport?GetDataCount=${perPage}&PerDataCount=${page}`)
                .then(res => res.json())
                .then(res => {
                    setFreeList([...freeList, ...res]);
                    setLoading(false);
                });
        }
        getFreeList();
    }, [page]);
    return (
        <div>
            <div className="main position-relative">
                <div className="container-fluid">
                    <div className="row justify-content-between mt-5 align-items-center">
                        <div className="section-text mt-5 col-lg-6">
                            <h2>Pulsuz al</h2>
                            {/*<span>*/}
                            {/*   Compete against other talented typists around the globe and show where the best typists  completing races and collecting points.*/}
                            {/*</span>*/}
                        </div>
                        {/*<div className="support-links col-lg-6 justify-content-end d-flex">*/}
                        {/*    <ul className="d-flex">*/}
                        {/*        <li className="line-link">Bitmiş müraciətlər</li>*/}
                        {/*        <li className="line-link">Bitmiş lahiyələr</li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </div>
                    <div className="support-gallery animated zoomIn">
                        <div className="row  mb-5">
                            {
                                freeList.map((f,i) => (
                                    <div className="col-xl-3 col-lg-4 col-12 mb-5">
                                        <div className="box">
                                            <div>
                                                <img src={f.images[0]?.url} alt=""/>
                                            </div>
                                            <div className="box-text">
                                                <span>{f.nameProduct}</span>
                                                <p>{f.note}</p>
                                            </div>

                                            <div className="box-link">
                                                <Link to={`/full-free-support/${f.id}`}><p>Ətraflı</p></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="links d-flex align-items-center justify-content-center mb-4">
                            { freeList.length  >= perPage ? <button className="btn-load-more" onClick={() => setPage(page + perPage)}>{loading ? 'Loading...' : t('loadMore')}</button> : " " }
                        </div>
                    </div>

                    <div className="payment-leaf1">
                        <img src={Image.Leaf2} alt=""/>
                    </div>
                    <div>
                        <img src={Image.OurCauses} alt="" className="donation"/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default FreeGet;
