import React, { useRef, useState, useEffect } from "react";
import Image from "../../../assets/img/Images";
import { Link, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Slider from "react-slick";
import Toast from "react-bootstrap/Toast";
import InputMask from "react-input-mask";
import Social from "../../Elements/Social/Social";
import { connect } from "react-redux";
import { getSupportFull } from "../../../redux/actions";
import { Switch } from "antd";
import vys from "../../../const/api";

function DonateNow(props) {
  const location = useLocation();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [ClinetName, setClinetName] = useState("");
  const [ClientEmail, setClientEmail] = useState("");
  const [ClientPhone, setClientPhone] = useState();

  const [Amount, setAmount] = useState();
  const [error, setError] = useState(false);
  const [selectedValue, setSelectedValue] = useState("1");
  const [anonymous, setAnonymous] = useState();

  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const { supportFull, getSupportFull } = props;
  const { id } = props.match.params;
  const [showA, setShowA] = useState(false);

  const toggleShowA = () => setShowA(!showA);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
    getSupportFull(id);
  }, []);

  const next = () => {
    slider2.current.slickNext();
  };
  const previous = () => {
    slider2.current.slickPrev();
  };
  console.log(supportFull);

  const donateNow = () => {
    if (
      ClientEmail === "" ||
      ClinetName === "" ||
      Amount === undefined ||
      ClientPhone === undefined
    ) {
      setError(true);
    } else {
      let obj = {
        SupportId: id,
        ClinetName,
        ClientEmail,
        ClientPhone,
        Amount,
        Isanonymous: anonymous,
        Type: Number(selectedValue),
      };

      vys.post("/CibPay", obj).then((result) => {
        window.location.replace(result?.data);
      });
    }
    setTimeout(() => {
      setError(false);
    }, 700);
  };

  // useEffect(() => {
  //   const orderId = new URLSearchParams(location.search).get("order_id");
  //   if (orderId) {
  //     console.log("id:", orderId);
  //   }
  // }, [location.search]);

  console.log(typeof selectedValue);
  return (
    <div>
      <div className="main position-relative">
        <div className="container-fluid">
          <div className="row mt-5 pt-5 mb-5">
            <div className="col-lg-6 col-12 animated fadeInLeftBig position-rel">
              <div className="section-text">
                <h2 className="m-0">{supportFull?.title}</h2>
              
                <span
                     dangerouslySetInnerHTML={{
                        __html: supportFull?.description
                    }} />
              </div>
              <div>
                <div className="range-percent d-flex justify-content-between align-items-center">
                  <p>Ümumi yığılan məbləğ</p>
                  <span>
                    {Math.floor(
                      (supportFull.accumulatedAmount /
                        supportFull.neededAmount) *
                      100
                    )}{" "}
                    %
                  </span>
                </div>
                <Form>
                  <Form.Group controlId="formBasicRange">

                    <div style={{ position: 'relative', width: '100%' }}>
                      <Form.Control
                        type="range"
                        value={Math.round(
                          (supportFull.accumulatedAmount /
                            supportFull.neededAmount) *
                          100
                        )}
                        style={{
                          background: 'transparent', // Arkaplanı şeffaf yapın
                          zIndex: 1, // range input'u diğer elemanların üstüne getirin
                          position: 'relative' // pozisyonu relative olarak ayarlayın
                        }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          border: '1px solid #ced4da', // Bozulmayan kısmın kenarlık rengi
                          borderRadius: '0.2rem', // İstediğiniz bir köşe yuvarlama miktarını ekleyin
                          background: `linear-gradient(90deg, #4daf42 0%, #4daf42 ${Math.round(supportFull.accumulatedAmount / supportFull.neededAmount * 100)}%, #fff ${Math.round(supportFull.accumulatedAmount / supportFull.neededAmount * 100)}%, #fff 100%)`
                        }}
                      />
                    </div>
                  </Form.Group>
                </Form>
                <div className="d-flex justify-content-between range-about">
                  <p>Yığılan məbləğ: {supportFull.accumulatedAmount} AZN</p>
                  <p>Yığılacaq məbləğ : {supportFull.neededAmount} AZN</p>
                </div>
              </div>
              <div className="donate-number d-flex justify-content-between">
                {/*<div>*/}
                {/*  <p>79</p>*/}
                {/*  <span>İanə edənlərin sayı</span>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <p>{supportFull.accumulatedAmount} AZN</p>*/}
                {/*  <span>Donated</span>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <p>14</p>*/}
                {/*  <span>Anonymously</span>*/}
                {/*</div>*/}
              </div>
              <div>
                <button
                  onClick={toggleShowA}
                  className="donate-form-button text-white"
                >
                  İanə et
                </button>

                <Toast show={showA} onClose={toggleShowA}>
                  <Toast.Body>
                    <div className="donate-form">
                      <form>
                        <div className="row mt-2">
                          {error ? (
                            <div className="text-danger col-12 text-center font-weight-bold">
                              Bütün xanalar doldurulmalıdır!
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                            <div className="flex mb-15">
                              <Switch
                                className="mr-15"
                                checked={anonymous}
                                onChange={() => {
                                  setAnonymous(!anonymous);
                                }}
                              />
                              <span className="mr-15">Status:</span>
                              <span className={anonymous ? "green" : "red"}>
                                {anonymous ? "Anonim" : "Anonim Deyil"}
                              </span>
                            </div>
                          </div>

                          <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                            <select
                              className="personsSelect  w-100"
                              id="personsSelect"
                              name="selects"
                              value={selectedValue}
                              onChange={(e) => {
                                setSelectedValue(e.target.value);
                              }}
                            >
                              <option value="1">Fərdi Şəxs</option>
                              <option value="2">Şirkət</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mt-2">
                          {selectedValue === "1" && (
                            <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                              <input
                                className="w-100"
                                type="text"
                                placeholder="Ad soyad"
                                onChange={(e) => {
                                  setClinetName(e.target.value);
                                }}
                              />
                            </div>
                          )}
                          {selectedValue === "2" && (
                            <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                              <input
                                className="w-100"
                                type="text"
                                placeholder="Şrkətin adı"
                                onChange={(e) => {
                                  setClinetName(e.target.value);
                                }}
                              />
                            </div>
                          )}
                          <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                            <input
                              className="w-100"
                              type="email"
                              placeholder="Email"
                              onChange={(e) => {
                                setClientEmail(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                            <InputMask
                              mask="+\9\9\4 99 999 99 99"
                              maskChar=""
                              className="w-100"
                              type="text"
                              placeholder="Əlaqə nömrəsi"
                              onChange={(e) => {
                                setClientPhone(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-xl-6 col-12 mb-xl-0 mb-3">
                            <input
                              className="w-100"
                              type="number"
                              placeholder="Məbləğ (AZN)"
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="row mt-2">
                          {/*<div className="col-xl-6 col-12 mb-xl-0 mb-3 d-flex align-items-center justify-content-between">*/}
                          {/*  <div>*/}
                          {/*    <span>*/}
                          {/*      Layf fonduna ayırmaq istədiyiniz məbləğ*/}
                          {/*    </span>*/}
                          {/*  </div>*/}
                          {/*  <select name="" id="">*/}
                          {/*    <option value="">15 %</option>*/}
                          {/*    <option value="">20 %</option>*/}
                          {/*    <option value="">25 %</option>*/}
                          {/*    <option value="">30 %</option>*/}
                          {/*  </select>*/}
                          {/*</div>*/}
                        </div>
                      </form>
                      <div className="links d-flex  align-items-center ">
                        <button
                          onClick={() => {
                            donateNow();
                          }}
                        >
                          İanə üçün keçıd et
                        </button>
                        <p className="anonim">
                          Anonim ianə etsəniz sizin adınız saytın "Hesabat"
                          bölməsində görünməyəcək
                        </p>
                      </div>
                    </div>
                  </Toast.Body>
                </Toast>
              </div>

              {/*<div className="d-flex align-items-center">*/}
              {/*  <div className="donor d-flex w-50">*/}
              {/*    <div>*/}
              {/*      <img src={Image.ProfilePic} alt="" />*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <img src={Image.ProfilePic} alt="" />*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <img src={Image.ProfilePic} alt="" />*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <img src={Image.ProfilePic} alt="" />*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <img src={Image.ProfilePic} alt="" />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="donor-link">*/}
              {/*    <Link to="/">Onlardan biri ol</Link>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="col-lg-6 col-12 animated fadeInRightBig">
              <div className="donate-slider-links d-flex float-right align-items-center">
                <Link>
                  <p className="line-link">PHOTO</p>
                </Link>
                <Link>
                  <p className="line-link">VIDEO</p>
                </Link>
                <div className="d-flex">
                  <div
                    className="mr-2"
                    onClick={() => {
                      previous();
                    }}
                  >
                    <img src={Image.Back} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      next();
                    }}
                  >
                    <img src={Image.Next} alt="" />
                  </div>
                </div>
              </div>
              <div className="donate-leaf">
                <img src={Image.Leaf2} alt="" />
              </div>
              <div className="donate-slider2">
                <Slider arrows={false} asNavFor={nav2} ref={slider1}>
                  {supportFull?.images?.map((p, i) => (
                    <div className="donate-slider1-box">
                      <img src={p.url} alt="" />
                      <div className="lay"></div>
                    </div>
                  ))}
                  {supportFull?.videos?.map((p, i) => (
                    <div className="donate-slider1-box">
                      {/*<video src={p.url} alt="" />*/}
                      <video loop autoPlay={true} muted={true} controls>
                        <source src={p.url} type="video/mp4"></source>
                      </video>
                      <img src={Image.Play} alt="" className="play" />
                      {/*<div className="lay"></div>*/}
                    </div>
                  ))}
                </Slider>

                {supportFull?.images?.length > 1 ||
                  supportFull?.videos?.length > 1 ? (
                  <Slider
                    asNavFor={nav1}
                    ref={slider2}
                    slidesToShow={3}
                    swipeToSlide={false}
                    focusOnSelect={true}
                    arrows={false}
                    className="mt-2"
                  >
                    {supportFull?.images?.map((p, i) => (
                      <div key={i} className="donate-slider2-box">
                        <img src={p.url} alt="" />
                        <div className="lay"></div>
                      </div>
                    ))}
                    {supportFull?.videos?.map((p, i) => (
                      <div className="donate-slider2-box">
                        {/*<video src={p.url} alt="" />*/}
                        <video loop autoPlay={true} muted={true} controls>
                          <source src={p.url} type="video/mp4"></source>
                        </video>
                        <img src={Image.Play} alt="" className="play2" />
                        {/*<div className="lay"></div>*/}
                      </div>
                    ))}
                  </Slider>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div>
            <img src={Image.Donation} alt="" className="donation" />
          </div>
          <Social />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ supportFull }) => {
  return { supportFull };
};

export default connect(mapStateToProps, { getSupportFull })(DonateNow);
