import React from 'react';
import { Switch, Route , Link} from "react-router";
import Home from "../../Pages/Home/Home";
import About from "../../Pages/About/About";
import SupportLayf from "../../Pages/SupportLayf/SupportLayf";
import MakeDream from "../../Pages/MakeDream/MakeDream";
import Media from "../../Pages/Media/Media";
import FreeSupportFull from "../../Pages/FreeSupportFull/FreeSupportFull";
import FreeDonate from "../../Pages/FreeDonate/FreeDonate";
import DonateNow from "../../Pages/DonateNow/DonateNow";
import Donates from "../../Pages/Donates/Donates";
import Payment from "../../Pages/Payment/Payment";
import ThanksPayment from "../../Pages/ThanksPayment/ThanksPayment";
import ApplyForm from "../ApplyForm/ApplyForm";
import Contact from "../Contact/Contact";
import FullStaff from "../../Pages/Full-Staff/FullStaff";
import FreeGet from "../../Pages/FreeGet/FreeGet";
import Staff from "../../Pages/Staff/Staff"
import FullPartners from "../../Pages/Full-Partners/FullPartners";
import FullMedia from "../../Pages/FullMedia/FullMedia";
import Rules from "../../Pages/Rules/Rules"
function Routes(props) {
    return (
        <div>
            <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/about" component={About} exact />
                <Route path="/support-layf" component={SupportLayf} exact />
                <Route path="/make-dream" component={MakeDream} exact />
                <Route path="/media" component={Media} exact />
                <Route path="/full-media/:id?" component={FullMedia} exact />
                <Route path="/full-free-support/:id?" component={FreeSupportFull} exact />
                <Route path="/free-donate" component={FreeDonate} exact />
                <Route path="/apply-form" component={ApplyForm} exact />
                <Route path="/donate-now/:id?" component={DonateNow} exact />
                <Route path="/donates" component={Donates} exact />
                <Route path="/payment" component={Payment} exact />
                <Route path="/thank-payment" component={ThanksPayment} exact />
                <Route path="/make-dream" component={MakeDream} exact />
                <Route path="/contact" component={Contact} exact />
                <Route path="/staff" component={Staff} exact />
                <Route path="/full-staff/:id?" component={FullStaff} exact />
                <Route path="/full-partners" component={FullPartners} exact />
                <Route path="/free-get" component={FreeGet} exact />
                <Route path="/rules" component={Rules} exact />
            </Switch>
        </div>
    );
}

Routes.propTypes = {};
Routes.defaultProps = {};

export default Routes;
