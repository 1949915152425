export const en = {
    rules: {
        rulesTitle: 'Terms of use',
        letter1: 'This Privacy Policy (hereinafter referred to as the “Privacy Policy”) applies to all information that an online resource located on the domain name https://layf.az can receive about the User while using the site, its programs and products.',
        letter2: '1. DEFINITION OF TERMS :',
        letter3: '1.1 This Privacy Policy applies to the following terms:',
        letter4: '1.1.1. “Administration of the site (hereinafter referred to as Site Administration)” – authorized employees on site management acting on behalf of https://layf.az, who organize and (or) perform personal data processing, and determine the purposes of processing personal data, the composition of personal data to be processed, actions (operations) performed with personal data.',
        letter5: '1.1.2. “Personal data” means any information related to a person directly or indirectly determined or determined by an individual (a subject of personal data).',
        letter6: '1.1.3. “Personal data processing” means any action (operation) or set of actions (operations) performed using automation tools or without using such means with personal data, including collection, recording, systematization, accumulation, storage, updating (updating, modification), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data.',
        letter7: '1.1.4. “Confidentiality of personal data” – a mandatory requirement for the Operator or other person who has access to a personal data to prevent their dissemination without the consent of the subject of personal data or other legal grounds.',
        letter8: '1.1.5. “Site user next (User)” means a person who has access to the Site through the Internet.',
        letter9: '1.1.6. “Cookies” is a small piece of data sent by a web server and stored on a user’s computer that the web client or web browser sends to the web server each time it tries to open the page of the corresponding site.',
        letter10: '1.1.7.”IP-address” is a unique network address of a node in a computer network built using the IP protocol.',
        letter11: '2.GENERAL PROVISIONS',
        letter12: '2.1. Use of the resource by the User means acceptance of this Privacy Policy and the terms of processing of the User’s personal data.' ,
        letter13: '2.2. In case of disagreement with the terms of the Privacy Policy, the User must stop using the site.',
        letter14: '2.3. This Privacy Policy applies only to the site https://layf.az, which does not control and does not bear responsibility for third-party sites, to which the User can click on the links available on the site.',
        letter15: '2.4. The site administration does not verify the authenticity of the personal data provided by the Site User.3. PRIVACY POLICY',
        letter16: '3.1. This Privacy Policy sets forth the obligations of the Site Administration to not disclose and provide a regime for protecting the confidentiality of personal data that the User provides upon the request of the Site Administration upon registration.' ,
        letter17: '3.2. Personal data authorized for processing under this Privacy Policy is provided by the User by filling out the registration form on the Site and includes the following information::',
        letter18: '3.2.1. Surname, name, report user;',
        letter19: '3.2.2. contact phone user;' ,
        letter20: '3.2.3. E-mail address;' ,
        letter21: '3.3. The site protects Data that is automatically transmitted during the viewing of ad units and when visiting pages on which the statistical script of the system (“pixel”) is installed:' ,
        letter22: '• IP address;' ,
        letter23: '• Information from cookies;' ,
        letter24: '3.3.1. The site collects statistics about the IP-addresses of its visitors. This information is used to identify and solve technical problems, to control the legality of financial payments.' ,
        letter25: '3.4. Any other personal information not specified above (payment history, browsers and operating systems used, etc.) is subject to secure storage and non-distribution, except as provided in cl. 5.2. this Privacy Policy.' ,
        letter26: '4. PURPOSE OF PERSONAL USER INFORMATION COLLECTION' ,
        letter27: '4.1. Administrators of site can use Personal Data in following cases:' ,
        letter28: '4.1.1. Identification of the User registered on the site for payment.' ,
        letter29: '4.1.2. Granting to the User, access to the personalized resources of the Site.' ,
        letter30: '4.1.3. Establishment of feedback from the User, including sending notifications, requests concerning the use of the Site, rendering services, processing requests and applications from the User.' ,
        letter31:    '4.1.4. Definitions of the location of the User for security, prevention of fraud.' ,
        letter32:    '4.1.5. Confirmation of the authenticity and completeness of the personal data provided by the User.' ,
        letter33:    '4.1.6. Create an account for payments, if the User has agreed to create an account.' ,
        letter34:    '4.1.7. Notifications of the User on the status of payment' ,
        letter35:    '4.1.8. Payment is not refundable, as it is done for charity purposes to help people.' ,
        letter36:    '4.1.9. Providing the Customer with effective technical support in case of problems related to the use of the Site.' ,
        letter37:    '4.1.10. Granting to the User with his consent, updates on the site, newsletters and other information on behalf of the Site or on behalf of the partners of the Site.' ,
        letter38:    '4.1.11. Implementation of advertising activities with the consent of the User.' ,
        letter39:    '5. METHODS AND TERMS OF PERSONAL INFORMATION PROCESSING' ,
        letter40:    '5.1. The processing of the User’s personal data is carried out without any time limit, in any legal way, including in personal data information systems using automation tools or without using such means.' ,
        letter41:    '5.2. Personal data of the User can be transferred to authorized state bodies of Azerbaijan only on the grounds and in the order established by the legislation of Azerbaijan Republic.' ,
        letter42:    '5.3. In case of loss or disclosure of personal data, the Administration of the site informs the User about the loss or disclosure of personal data.' ,
        letter43:    '5.4. The administration of the site takes necessary organizational and technical measures to protect personal information. User from unauthorized or accidental access, destruction, modification, blocking, copying, distribution, as well as from other illegal actions of third parties.' ,
        letter44:    '5.5. Administration of the site together with the User takes all necessary measures to prevent losses or other negative consequences caused by loss or disclosure of the User’s personal data.' ,
        letter45:    '6. OBLIGATIONS OF THE PARTIES' ,
        letter46:    '6.1. The User is obliged:' ,
        letter47:    '6.1.1. Provide information about the personal data necessary to use the Site.' ,
        letter48:    '6.1.2. Update, supplement the provided information about personal data in case of changing this information.' ,
        letter49:    '6.2. The Administration is obliged:' ,
        letter50:    '6.2.1. Use the information received solely for the purposes specified in clause 4 of this Privacy Policy.' ,
        letter51:    '6.2.2. To ensure that confidential information is not kept secret, not to disclose, without prior written permission of the User, as well as not payment, exchange, publication, or disclosure in other possible ways of personal data transmitted by the User, with the exception of clauses 5.2. this Privacy Policy.' ,
        letter52:    '6.2.3. Take precautions to protect the confidentiality of the User’s personal data in accordance with the procedure normally used to protect such information in the existing business.' ,
        letter53:    '6.2.4. To block personal data related to the relevant User from the moment of request or request of the User or his legal representative or authorized body for protection of the rights of subjects of personal data for the verification period, in case of revealing unreliable personal data or illegal actions.' ,
        letter54:    '7. LIABILITY OF THE PARTIES' ,
        letter55:    '7.1. The administration of the site that has not fulfilled its obligations is liable for losses incurred by the User in connection with the misuse of personal data in accordance with the legislation of the Republic of Azerbaijan' ,
        letter56:    '7.2. In case of loss or disclosure of Confidential Information, the Site Administration is not responsible if this confidential information:' ,
        letter57:    '7.2.1. Became public property before its loss or disclosure.' ,
        letter58:    '7.2.2. It was received from a third party until it was received by the Site Administration.' ,
        letter59:    '7.2.3. It was disclosed with the consent of the User.' ,
        letter60:    '',
    },
    contact:{
        contact:"Contact",
        wantToDonate:"Want to donate by phone?",
        callUs:"Call us now",
        ourEmail:"Or email us"
    },
    loadMore:"Load more causes",
}
